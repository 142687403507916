import React, { useEffect } from 'react';
import { makeStyles, createStyles, Theme, Container, Paper, Grid, Button } from '@material-ui/core';
import { useHistory, RouteComponentProps } from 'react-router-dom';
import MenuMaker from '.';
import { UserContext } from 'giftcards/core/context/UserContext';
import useSafeFetch from 'shared/hooks/useSafeFetch';
import { IMenu } from 'giftcards/core/context/MenusContext';
import { isLocal } from 'shared/utils/DevUtils';
import { useSpring, animated } from 'react-spring/web.cjs'; // web.cjs is required for IE 11 support
import SignUpModal from './SignUpModal';
import { CreateAccountResponseBody } from 'giftcards/auth/signup/SignUpFormContext';
import { SnackbarContext } from 'giftcards/core/context/SnackbarContext';
import { Alert } from '@material-ui/lab';

interface RouteParams {
  uri: string;
}

interface UserSignupInfo {
  businessName: string;
  email: string;
  phoneNumber: string;
  password: string;
}

interface UserSignUpRequest extends UserSignupInfo {
  username: string;
}

interface UpdateableMenuI {
  config: string;
  business_id?: string;
  uri?: string;
}

const OpenMenuMaker: React.FC<RouteComponentProps<RouteParams>> = props => {
  const history = useHistory();
  const { user, loading, refreshUser } = React.useContext(UserContext);
  const { get, put, post } = useSafeFetch();
  const [shouldCreate, setShouldCreate] = React.useState<boolean>(false);
  const [menu, setMenu] = React.useState<IMenu | undefined>(undefined);
  const [openModal, setOpenModal] = React.useState<boolean>(false);
  const [savedYml, setSavedYml] = React.useState<string>('');
  const { showSnackbarAlert } = React.useContext(SnackbarContext);
  const [showCannotEdit, setShowCannotEdit] = React.useState<boolean>(false);
  const classes = useStyles();

  useEffect(() => {
    refreshUser();
  }, []);

  useEffect(() => {
    if (!loading && user) {
      history.push('/mymenumaker');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  // TODO refactor into common
  const createMenu = async (menuPayload: UpdateableMenuI) => {
    const res = await post(`${process.env.REACT_APP_PORTAL}/menus`, {
      redirectToLogin: false,
      body: JSON.stringify(menuPayload)
    });
    const menu: IMenu = await res.json();
    return menu;
  };

  const updateMenu = async (uri: string, menuPayload: UpdateableMenuI) => {
    const res = await put(`${process.env.REACT_APP_PORTAL}/menus/${uri}`, {
      redirectToLogin: false,
      body: JSON.stringify(menuPayload)
    });
    const menu: IMenu = await res.json();
    return menu;
  };

  const getMenu = async (uri: string) => {
    const res = await get(`${process.env.REACT_APP_PORTAL}/menus/${props.match.params.uri}`, {
      redirectToLogin: false
    });
    if (!res.ok) {
      throw { response: res };
    }
    const menu: IMenu = await res.json();
    return menu;
  };

  useEffect(() => {
    if (props.match.params.uri != undefined) {
      //should create if 404
      getMenu(props.match.params.uri)
        .then(menu => {
          setMenu(menu);
          if (menu.business_id != undefined) {
            setShowCannotEdit(true);
          }
        })
        .catch(error => {
          console.log('in error');
          if (error.response && error.response.status == 404) {
            console.log('in error response 404');
            setShouldCreate(true);
          }
        });
    } else {
      setShouldCreate(true);
    }
  }, [props.match.params.uri]);

  const onSave = (yml: string) => {
    setSavedYml(yml);
    setOpenModal(true);
  };

  const onSaveAnon = async (yml: string) => {
    setOpenModal(false);
    if (shouldCreate) {
      // creating menu
      const payload = { config: yml, uri: props.match.params.uri };
      const menu = await createMenu(payload);
      setShouldCreate(false);
      history.push(`/menumaker/${menu.uri}`);
    } else {
      // updating anon menu
      const payload = { config: yml };
      await updateMenu(props.match.params.uri, payload);
    }
    !showCannotEdit && showSnackbarAlert('Saved!', 'success');
  };

  const onSaveWithNewAccount = async (account: CreateAccountResponseBody, yml: string) => {
    setOpenModal(false);
    if (shouldCreate) {
      const payload = { config: yml, uri: props.match.params.uri, business_id: account.id };
      await createMenu(payload);
    } else {
      const payload = { config: yml, business_id: account.id };
      await updateMenu(props.match.params.uri, payload);
    }
    history.push('/mymenumaker');
  };

  const workingUri = shouldCreate ? undefined : props.match.params.uri;

  return (
    <>
      {showCannotEdit && (
        <Alert className={classes.alert} elevation={0} variant='filled' severity='error'>
          This menu is restricted. Please log in to edit.
        </Alert>
      )}
      <MenuMaker save={onSave} menuYml={menu?.config} menuUri={workingUri} />
      <SignUpModal
        open={openModal}
        onClose={() => setOpenModal(false)}
        onAccountCreated={account => onSaveWithNewAccount(account, savedYml)}
        onSaveAnon={() => onSaveAnon(savedYml)}
      />
    </>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    alert: {
      marginTop: theme.spacing(2)
    }
  })
);
export default OpenMenuMaker;
